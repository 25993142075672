// Don't apply 'HeadingBuilder' for this component's 'Tippy content' ('submit' field)
import React, { useContext, useState, useRef, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import * as Constants from '../constants';
import { ShopperContext } from '../context/shopper';
import HeadingBuilder from '../components/HeadingBuilder';
import ShowAlert from '../components/ShowAlert';
import { ErrorList } from './ErrorList';
import { BackButton } from './BackButton';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { useLookups, usePhrasing } from "../hooks/useSkuUtils";
import { RadioButton } from "./RadioButton";
import { StripeCircleBuyButton } from "./StripeCircleBuyButton";
import Table from 'react-bootstrap/Table';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";
import styles from '../css/trTdFirstChild.module.css';

const CircleForm = ({lightUpAddCircleButton}) => {
  const { signedIn, shopper, addCircle, clientReferenceId, setClientReferenceId,
          errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse,
          stripeIsActive,
          setJustGotClearToFalse
        } = useContext(ShopperContext);
  const { tableShow, tableData } = useStyles();
  const { lookup_tz_info } = useLookups();
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
   if (signedIn) {
    inputRef.current.focus();
   }
  }, [signedIn]);

  // console.log('cf stripeIsActive', stripeIsActive)
  const defaultPlan =  stripeIsActive ? 'weekly3us200' : 'free00';
  const [whichPlan, setWhichPlan] = useState(defaultPlan);

  const [showAddCircleFormAlert, setShowAddCircleFormAlert] = useState(false);
  const toggleAddCircleFormAlert = () => {
    setShowAddCircleFormAlert(! showAddCircleFormAlert);
  }

  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const toggleInfoAlert = () =>  setShowInfoAlert(! showInfoAlert);
  

  // TODO: present commented-out attributes for entry in some Profile update
  const initialState = {
    identifier: '',
    label: '',
    postal_code:  shopper.postal_code || '99999',
    time_zone: "Eastern Time (US & Canada)",
    tax_rate: '',
    tax_rate_food: '',
    plan_identifier: defaultPlan,
    // show_help_video_icons: shopperObj.shopper.show_help_video_icons ? 1 : 0,
    share_sku_basic: 1,
    // detail: '',
    // img_url: '',
    // city: '',
    // county: '',
    // state: '',
    // country: '',
  };
  const { formData, handleChange, reset, editFormValues } = useForm(initialState);

  const resetCircleForm = () => {
    setClientReferenceId('');
    reset();
  }

  const hideCircleForm = () => {
    setClientReferenceId('');
    lightUpAddCircleButton();
  }

  // A RadioButton shows as 'selected' when its 'isChecked' attribute is true.
  // The only RadioButton whose 'isChecked' attribute is true will be the one
  // whose 'planName' matches the value in 'whichPlan'.
  // Clicking a RadioButton runs 'setWhichPlan' with the plan's 'planName',
  // causing 'isChecked' to be true for the clicked RadioButton.

  // Note - as an event handler, handlePickPlan only activates by clicking
  // a RadioButton. Unless that happens, the 'plan_identifier' value that was
  // assigned via initialState will be what's provided upon Submit.
  // Initializing 'whichPlan' via useState(defaultPlan) does not set the form's
  // 'plan_identifier' attribute. But, by making the initial 'whichPlan' and
  // 'initialState.plan_identifier' values the same, then the "prepicked" button
  // will correctly indicate the 'plan_identifier' value configured to submit.
  const handlePickPlan = (planName) => {
    setWhichPlan(planName);  // lights up/selects that RadioButton
    handleChange({ target: {name: 'plan_identifier', value: planName} });
  };

  const handle1MonthFree5Change = () => {
    handlePickPlan('free00');
  };
  const handleWeekly3Change = () => {
    handlePickPlan('weekly3us200');
  };
  const handleMonthly3Change = () => {
    handlePickPlan('monthly3us300');
  };
  const handleBiannual3Change = () => {
    handlePickPlan('biannual3us1300');
  };
  const handleMonthly5Change = () => {
    handlePickPlan('monthly5us500');
  };
  const handleBiannual5Change = () => {
    handlePickPlan('biannual5us1900');
  };
  const handleMonthly8Change = () => {
    handlePickPlan('monthly8us800');
  };
  const handleBiannual8Change = () => {
    handlePickPlan('biannual8us2900');
  };

  const { planParticulars,
          circleTaxTippyText, circleFoodTaxTippyText } = usePhrasing();
  const circleTaxText     = circleTaxTippyText();
  const circleFoodTaxText = circleFoodTaxTippyText();

 if (signedIn) {
  const handleSubmit = (e) => {
    e.preventDefault();

    dismissErrorsList();
    setErrorsDoExistToFalse();
    setJustGotClearToFalse();
    setClientReferenceId('');

    console.log('formData for addCircle: ', formData);
    addCircle(formData);
  }

  const limits_3_weekly = planParticulars({
    maxTrips:       Constants.MAX_PLAN_TRIPS_3,
    maxOrders:      Constants.MAX_PLAN_OITEMS_3,
    maxSkuSearches: Constants.MAX_PLAN_SKU_SEARCHES_WEEKLY_3,
    period: t('global.weekly')
  });
  // Hold 100 Trips and 2000 OrderItems // Make 100 Sku Searches weekly

  const limits_3 = planParticulars({
    maxTrips:       Constants.MAX_PLAN_TRIPS_3,
    maxOrders:      Constants.MAX_PLAN_OITEMS_3,
    maxSkuSearches: Constants.MAX_PLAN_SKU_SEARCHES_3
  });
  // Hold 100 Trips and 2000 OrderItems // Make 500 Sku Searches monthly

  const limits_5 = planParticulars({
    maxTrips:       Constants.MAX_PLAN_TRIPS_5,
    maxOrders:      Constants.MAX_PLAN_OITEMS_5,
    maxSkuSearches: Constants.MAX_PLAN_SKU_SEARCHES_5
  });
  // Hold 120 Trips and 3000 OrderItems // Make 750 Sku Searches monthly

  const limits_8 = planParticulars({
    maxTrips:       Constants.MAX_PLAN_TRIPS_8,
    maxOrders:      Constants.MAX_PLAN_OITEMS_8,
    maxSkuSearches: Constants.MAX_PLAN_SKU_SEARCHES_8
  });
  // Hold 140 Trips and 5000 OrderItems // Make 1000 Sku Searches monthly

  const pickPlanTippyContent = t('circle.pick_a_plan.tippyContent010') +
     Constants.MAX_PLAN_SKUS + t('circle.pick_a_plan.tippyContent020');

  const zipText = t('circle.postal_code.tippyContent')
          + " " + t('circle.postal_code.tippyContent99999');

  const seemsLikeFreePlan = whichPlan.search(/free/i) > -1;
  // console.log('seemsLikeFreePlan is ', seemsLikeFreePlan);

  return (
      <article>
        <HeadingBuilder
          Size = "h4"
          headingText = {t('circleForm.formPart.headlineLabel')}
          // Make a new Shopping Circle: VID
          tippyAlertText = {t('circleForm.formPart.headlineTippyContent')}
          tippyText = {t('circleForm.formPart.headlineTippyContent')}
          videoUrl = "video/add-circle.mp4"
        />
        <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('circle.leader_email.label')}
          // Leader's Email
          labelHtmlFor = "leader_email_cf"
          tippyAlertText = {t('circle.leader_email.tippyContent')}
          tippyText = {t('circle.leader_email.tippyContent')}
        />
      </td>
      <td>
        <HeadingBuilder
          containerType  = 'container-left'
          headingText    = {shopper.email}
          // (Shopper's email address - they will become the leader.)
          linkUrl        = {`/shoppers/${shopper.id}/edit`}
          tippyAlertText = {t('circle.leader_email_link.tippyContent')}
          tippyText      = {t('circle.leader_email_link.tippyContent')}
          // You can change this email address by clicking the link here to access your profile.
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('circle.postal_code.label')}
          // Zip Code
          labelHtmlFor = "postal_code_cf"
          tippyAlertText = {zipText}
          tippyText = {zipText}
        />
      </td>
      <td>
        <input
          key="20"
          type="text"
          id="postal_code_cf"
          name="postal_code"
          onChange={handleChange}
          value={formData.postal_code}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('circle.identifier.label')}
          // Shopping Circle ID:
          labelHtmlFor = "identifier_cf"
          tippyAlertText = {t('circle.identifier.tippyContent')}
          tippyText = {t('circle.identifier.tippyContent')}
        />
      </td>
      <td>
        <input
          key="30"
          ref={inputRef}
          type="text"
          style={{ textTransform: "lowercase" }}
          id="identifier_cf"
          name="identifier"
          // autoComplete="username"
          onChange={handleChange}
          value={formData.identifier}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('circle.label.label')}
          // Description
          labelHtmlFor = "label_cf"
          tippyAlertText = {t('circle.label.tippyContent')}
          tippyText = {t('circle.label.tippyContent')}
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          id="label_cf"
          name="label"
          onChange={handleChange}
          value={formData.label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.time_zone.label')}
          // Time Zone
          labelHtmlFor   = "time_zone_cf"
          tippyAlertText = {t('circle.time_zone.tippyContent')}
          tippyText      = {t('circle.time_zone.tippyContent')}
          // Where will your circle operate?
        />
      </td>
      <td>
        <select
          key="45"
          type="text"
          id="time_zone_cf"
          name="time_zone"
          onChange={handleChange}
          value={formData.time_zone}
        >
          {/* <option value="">Choose one:/option> */}
          <option value='' disabled>{t('global.chooseOne')}</option>
          <option value={lookup_tz_info({tz_term: "guam"})}
            >{t('circle.tz_name.guam')}</option>
          <option value={lookup_tz_info({tz_term: "midway_island"})}
            >{t('circle.tz_name.midway_island')}</option>
          <option value={lookup_tz_info({tz_term: "american_samoa"})}
            >{t('circle.tz_name.american_samoa')}</option>
          <option value={lookup_tz_info({tz_term: "hawaii"})}
            >{t('circle.tz_name.hawaii')}</option>
          <option value={lookup_tz_info({tz_term: "alaska"})}
            >{t('circle.tz_name.alaska')}</option>
          <option value={lookup_tz_info({tz_term: "pacific_time_us_n_canada"})}
            >{t('circle.tz_name.pacific_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "arizona"})}
            >{t('circle.tz_name.arizona')}</option>
          <option value={lookup_tz_info({tz_term: "mountain_time_us_n_canada"})}
            >{t('circle.tz_name.mountain_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "central_time_us_n_canada"})}
            >{t('circle.tz_name.central_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "indiana_east"})}
            >{t('circle.tz_name.indiana_east')}</option>
          <option value={lookup_tz_info({tz_term: "eastern_time_us_n_canada"})}
            >{t('circle.tz_name.eastern_time_us_n_canada')}</option>
          <option value={lookup_tz_info({tz_term: "puerto_rico"})}
            >{t('circle.tz_name.puerto_rico')}</option>
          <option value={lookup_tz_info({tz_term: "fiji"})}
            >{t('circle.tz_name.fiji')}</option>
          <option value={lookup_tz_info({tz_term: "wellington"})}
            >{t('circle.tz_name.wellington')}</option>
          <option value={lookup_tz_info({tz_term: "new_caledonia"})}
            >{t('circle.tz_name.new_caledonia')}</option>
          <option value={lookup_tz_info({tz_term: "sydney"})}
            >{t('circle.tz_name.sydney')}</option>
          <option value={lookup_tz_info({tz_term: "melbourne"})}
            >{t('circle.tz_name.melbourne')}</option>
          <option value={lookup_tz_info({tz_term: "darwin"})}
            >{t('circle.tz_name.darwin')}</option>
          <option value={lookup_tz_info({tz_term: "tokyo"})}
            >{t('circle.tz_name.tokyo')}</option>
          <option value={lookup_tz_info({tz_term: "seoul"})}
            >{t('circle.tz_name.seoul')}</option>
          <option value={lookup_tz_info({tz_term: "perth"})}
            >{t('circle.tz_name.perth')}</option>
          <option value={lookup_tz_info({tz_term: "singapore"})}
            >{t('circle.tz_name.singapore')}</option>
          <option value={lookup_tz_info({tz_term: "bangkok"})}
            >{t('circle.tz_name.bangkok')}</option>
          <option value={lookup_tz_info({tz_term: "rangoon"})}
            >{t('circle.tz_name.rangoon')}</option>
          <option value={lookup_tz_info({tz_term: "dhaka"})}
            >{t('circle.tz_name.dhaka')}</option>
          <option value={lookup_tz_info({tz_term: "kathmandu"})}
            >{t('circle.tz_name.kathmandu')}</option>
          <option value={lookup_tz_info({tz_term: "kolcata"})}
            >{t('circle.tz_name.kolcata')}</option>
          <option value={lookup_tz_info({tz_term: "mumbai"})}
            >{t('circle.tz_name.mumbai')}</option>
          <option value={lookup_tz_info({tz_term: "islamabad"})}
            >{t('circle.tz_name.islamabad')}</option>
          <option value={lookup_tz_info({tz_term: "karachi"})}
            >{t('circle.tz_name.karachi')}</option>
          <option value={lookup_tz_info({tz_term: "kabul"})}
            >{t('circle.tz_name.kabul')}</option>
          <option value={lookup_tz_info({tz_term: "baku"})}
            >{t('circle.tz_name.baku')}</option>
          <option value={lookup_tz_info({tz_term: "tehran"})}
            >{t('circle.tz_name.tehran')}</option>
          <option value={lookup_tz_info({tz_term: "nairobi"})}
            >{t('circle.tz_name.nairobi')}</option>
          <option value={lookup_tz_info({tz_term: "cairo"})}
            >{t('circle.tz_name.cairo')}</option>
          <option value={lookup_tz_info({tz_term: "jerusalem"})}
            >{t('circle.tz_name.jerusalem')}</option>
          <option value={lookup_tz_info({tz_term: "kyiv"})}
            >{t('circle.tz_name.kyiv')}</option>
          <option value={lookup_tz_info({tz_term: "riga"})}
            >{t('circle.tz_name.riga')}</option>
          <option value={lookup_tz_info({tz_term: "berlin"})}
            >{t('circle.tz_name.berlin')}</option>
          <option value={lookup_tz_info({tz_term: "madrid"})}
            >{t('circle.tz_name.madrid')}</option>
          <option value={lookup_tz_info({tz_term: "west_central_africa"})}
            >{t('circle.tz_name.west_central_africa')}</option>
          <option value={lookup_tz_info({tz_term: "london"})}
            >{t('circle.tz_name.london')}</option>
          <option value={lookup_tz_info({tz_term: "monrovia"})}
            >{t('circle.tz_name.monrovia')}</option>
          <option value={lookup_tz_info({tz_term: "azores"})}
            >{t('circle.tz_name.azores')}</option>
          <option value={lookup_tz_info({tz_term: "barasilia"})}
            >{t('circle.tz_name.barasilia')}</option>
          <option value={lookup_tz_info({tz_term: "buenos_aires"})}
            >{t('circle.tz_name.buenos_aires')}</option>
          <option value={lookup_tz_info({tz_term: "montevideo"})}
            >{t('circle.tz_name.montevideo')}</option>
          <option value={lookup_tz_info({tz_term: "newfoundland"})}
            >{t('circle.tz_name.newfoundland')}</option>
          <option value={lookup_tz_info({tz_term: "georgetown"})}
            >{t('circle.tz_name.georgetown')}</option>
          <option value={lookup_tz_info({tz_term: "santiago"})}
            >{t('circle.tz_name.santiago')}</option>
          <option value={lookup_tz_info({tz_term: "caracas"})}
            >{t('circle.tz_name.caracas')}</option>
          <option value={lookup_tz_info({tz_term: "lima"})}
            >{t('circle.tz_name.lima')}</option>
          <option value={lookup_tz_info({tz_term: "bogota"})}
            >{t('circle.tz_name.bogota')}</option>
          <option value={lookup_tz_info({tz_term: "mexico_city"})}
            >{t('circle.tz_name.mexico_city')}</option>
          <option value={lookup_tz_info({tz_term: "saskatchewan"})}
            >{t('circle.tz_name.saskatchewan')}</option>
          <option value={lookup_tz_info({tz_term: "mazatlan"})}
            >{t('circle.tz_name.mazatlan')}</option>
          <option value={lookup_tz_info({tz_term: "tijuana"})}
            >{t('circle.tz_name.tijuana')}</option>
        </select>
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('circle.share_sku_basic.label')}
            // Share basic Sku info
          labelHtmlFor   = "share_sku_basic"
          tippyAlertText = {t('circle.share_sku_basic.tippyContent')}
          tippyText      = {t('circle.share_sku_basic.tippyContent')}
          // A 'yes' value here allows ShopWeAll to copy basic information...
        />
      </td>
      <td>
        <select
          key="45"
          type="text"
          id="share_sku_basic"
          name="share_sku_basic"
          onChange={handleChange}
          value={formData.share_sku_basic}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('shopper.showVideoTipsIcon.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('shopper.showVideoTipsIcon.optionValue1')}</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('circle.tax_rate.label')}
          // Sales Tax Rate
          labelHtmlFor = "tax_rate"
          tippyAlertText = {circleTaxText}
          tippyText      = {circleTaxText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="50"
          type="text"
          id="tax_rate"
          name="tax_rate"
          onChange={handleChange}
          value={formData.tax_rate}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('circle.tax_rate_food.label')}
          // Food Sales Tax Rate
          labelHtmlFor = "tax_rate_food"
          tippyAlertText = {circleFoodTaxText}
          tippyText      = {circleFoodTaxText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="60"
          type="text"
          id="tax_rate_food"
          name="tax_rate_food"
          onChange={handleChange}
          value={formData.tax_rate_food}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText = {t('circle.pick_a_plan.label')}
          // Choose a Plan
          labelHtmlFor = "pick_a_plan"
          tippyAlertText = {pickPlanTippyContent}
          tippyText = {pickPlanTippyContent}
        />
      </td>
      <td>
       { stripeIsActive ? <>
        <RadioButton
          key="74"
          label={t('circle.planPriceBasic.label_3')}
          //" Up to 3 Shoppers, Weekly - $2.00"
          tippyAlert={limits_3_weekly}
          onChange={handleWeekly3Change}
          isChecked={whichPlan === 'weekly3us200'}
        />
        <RadioButton
          key="76"
          label={t('circle.planPriceBasic.label_3_1')}
          // " Up to 3 Shoppers, Monthly - $3.00"
          tippyAlert={limits_3}
          onChange={handleMonthly3Change}
          isChecked={whichPlan === 'monthly3us300'}
        />
        <RadioButton
          key="78"
          label={t('circle.planPriceBasic.label_3_6')}
          // " Up to 3 Shoppers, 6 Months - $13.00"  // 4.3 * $3
          tippyAlert={limits_3}
          tippyInfo={
              t('circle.planPriceBasic.tippyContent_3_6_A')
            + Constants.ONE_THIRD
            + t('circle.planPriceBasic.tippyContent_3_6_B')
            + Constants.TWO_THIRDS
            + t('circle.planPriceBasic.tippyContent_3_6_C')
          }
          // "Pay for 4 1/3 Months and get 1 2/3 Months free"
          onChange={handleBiannual3Change}
          isChecked={whichPlan === 'biannual3us1300'}
        />
        <RadioButton
          key="80"
          label={t('circle.planPriceBasic.label_5_1')}
          // " Up to 5 Shoppers, Monthly - $5.00"
          tippyAlert={limits_5}
          onChange={handleMonthly5Change}
          isChecked={whichPlan === 'monthly5us500'}
        />
        <RadioButton
          key="90"
          label={t('circle.planPriceBasic.label_5_6')}
          // " Up to 5 Shoppers, 6 Months - $19.00"  // 3.8 * $5
          tippyAlert={limits_5}
          tippyInfo={t('circle.planPriceBasic.tippyContent_5_6')}
          // "Pay for 3.8 Months and get 2.2 Months free"
          onChange={handleBiannual5Change}
          isChecked={whichPlan === 'biannual5us1900'}
        />
        <RadioButton
          key="100"
          label={t('circle.planPriceBasic.label_8_1')}
          // " Up to 8 Shoppers, Monthly - $8.00"
          tippyAlert={limits_8}
          onChange={handleMonthly8Change}
          isChecked={whichPlan === 'monthly8us800'}
        />
        <RadioButton
          key="110"
          label={t('circle.planPriceBasic.label_8_6')}
          // " Up to 8 Shoppers, 6 Months - $29.00"  // 3.6 * $8
          tippyAlert={limits_8}
          tippyInfo={
              t('circle.planPriceBasic.tippyContent_8_6_A')
            + Constants.FIVE_EIGHTHS
            + t('circle.planPriceBasic.tippyContent_8_6_B')
            + Constants.THREE_EIGHTHS
            + t('circle.planPriceBasic.tippyContent_8_6_C')
          }
          // "Pay for 3 5/8 Months and get 2 3/8 Months free"
          onChange={handleBiannual8Change}
          isChecked={whichPlan === 'biannual8us2900'}
        />
       </>
       : <RadioButton
          key="70"
          label=" Up to 5 Shoppers, 1 Month - $0.00 (subject to change)"
          tippyAlert={limits_5}
          onChange={handle1MonthFree5Change}
          isChecked={whichPlan === 'free00'}
         />
      }
      </td>
    </tr>
  </tbody>
</Table>
          { errorsDoExist && <ErrorList /> }
         <div className="oneline-container">
          <HeadingBuilder
            // ? for: Submit
            tippyAlertText = {t('circle.addcircle_explainer.tippyContent')}
            showTippyAlert = {showAddCircleFormAlert}
            toggleTippyAlert = {toggleAddCircleFormAlert}
          />
          <Tippy content= {t('circle.addcircle_explainer.tippyContent')} >
            <input key="200" type='submit' value='Submit' />
          </Tippy>&nbsp;
          { stripeIsActive
            ? <HeadingBuilder
                tippyInfoText = {t('circle.addcircle_explainer.tippyContentStripe')}
                // Clicking 'Submit' creates a Circle with 'payment_status' of 'pending'. A 'Subscribe' button soon appears...
                showInfoAlert = {showInfoAlert}
                toggleInfoAlert = {toggleInfoAlert}
              />
            : null
          }
         </div>
         <ShowAlert showAlert = {showAddCircleFormAlert}
                     alertMsg = {t('circle.addcircle_explainer.tippyContent')} />
         <ShowAlert showAlert = {showInfoAlert}
                     alertMsg = {t('circle.addcircle_explainer.tippyContentStripe')} />
        </form>
        <br/>
        <Table responsive>
          <thead>
          </thead>
          <tbody>
            <tr>
              <td>
            <HeadingBuilder
              buttonAction   = {resetCircleForm}
              headingText    = {t('global.label.reset')}
              // Reset
              tippyAlertText = {t('circle.addcircle_resetButton.tippyContent')}
              tippyText      = {t('circle.addcircle_resetButton.tippyContent')}
            />
              </td>
              <td>
            <HeadingBuilder
              buttonAction   = {hideCircleForm}
              headingText    = {t('global.label.hide')}
              // Hide
              tippyAlertText = {t('circle.addcircle_hideButton.tippyContent')}
              tippyText      = {t('circle.addcircle_hideButton.tippyContent')}
            />
              </td>
            </tr>
          </tbody>
        </Table>
        {
          // If handleSubmit has caused setting a value for clientReferenceId
          // then show the associated Stripe Buy Button
          clientReferenceId.length > 0

          // ".length" should filter out "free" circles, but just in case:
          &&  !seemsLikeFreePlan

          &&
         <>
          <HeadingBuilder
            headingText    = {t('circle.addcircle_explainer.headingSubscribe')}
            // Click 'Subscribe' to arrange payment and activate Circle
            tippyAlertText = {t('circle.addcircle_explainer.tippyContentSubscribe')}
            tippyText      = {t('circle.addcircle_explainer.tippyContentSubscribe')}
            // Note: Clicking 'Subscribe' will open a tab that does not have a 'Cancel' button...
            tippyInfoText  = {t('circle.addcircle_explainer.tippyContentSubscribeInfo')}
          />
          <StripeCircleBuyButton
            whichPlan = {whichPlan}
          />
         </>
        }
        <BackButton />
      </article>
  )

 } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
 }
}

export default CircleForm;
