import React from 'react';
import * as Constants from '../constants'
import { useLookups, usePricing, usePhrasing } from "../hooks/useSkuUtils";
import { useStyles } from "../hooks/useStyles";
import { HeadingBuilder } from "./HeadingBuilder";
       
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "react-i18next";

export const SkuFoundRow = ({ circle_id,
                            otherSku,
                            showIdentifier,
                            showPostalCode,
                            showUnitCost,
                            showSkuDetailsColumn
                           }) => {
  console.log("sfr otherSkuA", circle_id, otherSku);

  const { t } = useTranslation();
  const { getUnitCost } = usePricing();
  const { tableOrderItem } = useStyles();
  const { weightAndUnitsOfSku, noDotZero, getOColTippyContent } = usePhrasing();
    
  const { lookup_category } = useLookups();

  const o_colTippyText      = getOColTippyContent({ isForTextNotAudio: true });
  const o_colAudioTippyText = getOColTippyContent({ isForTextNotAudio: false });

  const otherPriceFloat = 
    parseFloat(otherSku.shared_price)
      -  (parseFloat(otherSku.shared_discount_amount) || 0);

  const otherPrice = '$' + otherPriceFloat.toFixed(2).toString();
  // $7.00

  const otherPriceTippyText =
    t('sku.price_date.searchLabel') + ' ' + otherSku.shared_price_date;
    // Price AsOf: 2024-05-21

  const otherPriceTippyInfoText =
    otherSku.shared_discount_amount > 0
      ? t('price.colHeader.normalPrice') + ": $" +
          parseFloat(otherSku.shared_price).toFixed(2).toString() + ' // ' +
          // "Normal price: $8.00 // "
        t('price.colHeader.discount') + ": $" +
          parseFloat(otherSku.shared_discount_amount).toFixed(2).toString() + ' // ' +
          // "Discount: $1.00 // "
        t('price.colHeader.discEnds') + ": " + otherSku.shared_discount_end_date
          // "Disc ends: 2024-05-30"
      : '';

  // checkbox advice per https://www.robinwieruch.de/react-checkbox/
  // button advice per https://www.robinwieruch.de/react-button/
  // https://upmostly.com/tutorials/pass-a-parameter-through-onclick-in-react
  return (
    <tr style={tableOrderItem}>

      {/* "O" button: */}
      <td>
        <HeadingBuilder
          tippyAlertText = {o_colTippyText}
          tippyAlertAudio= {o_colAudioTippyText}
          tippyText      = {o_colTippyText}
          blueLinkUrl    = {`/circles/${circle_id}/skus/${otherSku.uuuid}`}
          headingText    = {Constants.O_COL_SYM}
          // Click the O sign to choose that row's search results as the starting material for adding a new Sku to your Circle. Your Sku will need a Price and 'As Of' date for the Price.
        />
      </td>
      {/* ID */}
      <td>
        { showIdentifier
          ?
            otherSku.identifier.length > Constants.MAX_SKU_IDENTIFIER_CHARS_BEFORE_TIPPY
              ? < HeadingBuilder
                  containerType  = 'ellipsis fw-bold'
                  headingText    = {otherSku.identifier}
                  tippyAlertText = {otherSku.identifier}
                  tippyAlertIcon = {Constants.GET_ICON_ASTERISK}
                  tippyText      = {otherSku.identifier}
                />
              : <b>{otherSku.identifier}</b>
              
          : < HeadingBuilder
              headingText    = {Constants.DOT}
              // text of '.', which won't even display
              tippyAlertText = {otherSku.identifier}
              tippyAlertIcon = {Constants.GET_ICON_ASTERISK}
              tippyText      = {otherSku.identifier}
              forceShowAlertText = '1'
            />
        }
      </td>
      {/* Zip */}
      <td>
        { showPostalCode
          ?
            < HeadingBuilder
              headingText    = {'-' + otherSku.shared_circle_postal_code}
              // -99999
              tippyInfoText  = {otherSku.shared_circle_identifier}
            />
              
          : < HeadingBuilder
              headingText    = {Constants.DOT}
              // text of '.', which won't even display
              tippyAlertText = {otherSku.shared_circle_postal_code}
              tippyAlertIcon = {Constants.GET_ICON_ASTERISK}
              tippyText      = {otherSku.shared_circle_postal_code}
              tippyInfoText  = {otherSku.shared_circle_identifier}
              forceShowAlertText = '1'
            />
        }
      </td>
      <td>{otherSku.brand}</td>
      <td>
        <HeadingBuilder
          containerType  = 'container-left'
          headingText    = {
            otherSku.label +
            weightAndUnitsOfSku({
              sku: otherSku,
              showWeightAsSkuEditLink: false,
              showUnitCount: true,
              showQtyUnits: false
            })
          }
          tippyInfoText  = { lookup_category({category: otherSku.category}) }
          // e.g.: Milk, 1%, organic (qty 3) (!)
          // where 'tippyText' shows as 'Dairy'
          // After the label we'll show unit counts for discrete items and weight info for non-discrete items
        />
      </td>
      <td>{otherSku.store_label}</td>
      <td>
        {noDotZero(
          {num:  otherSku.quantity === 0  ? '' : otherSku.quantity }
                    )} {otherSku.units}
      </td>
      <td>
        <HeadingBuilder
          containerType  = 'container-left'
          headingText    = {otherPrice}
          // $7.00
          tippyAlertText = {otherPriceTippyText}
          tippyText      = {otherPriceTippyText}
          tippyInfoText  = {otherPriceTippyInfoText}
        />
        &nbsp;
      </td>
      {
        showUnitCost
          ? <td>
              ${ 
                getUnitCost ({
                  sku: otherSku,
                  sku_price: otherPriceFloat
                })
              }
              /{otherSku.units.replace(/[(]s[)]$/, '')}
            </td>
          : null
      }
      <td>
        { showSkuDetailsColumn
          ? otherSku.detail
          : null
        }
      </td>
    </tr>
  )
}

export default SkuFoundRow;
