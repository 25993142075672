import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import * as Constants from '../constants';
import CircleForm from '../components/CircleForm'
import CircleList from '../components/CircleList'
import HeadingBuilder from '../components/HeadingBuilder';
import { useTranslation } from "react-i18next";

const Circles = () => {
  const { circles, shopper, signedIn,
        } = useContext(ShopperContext);
  const [formFlag, setFormFlag] = useState(false);
  const lightUpAddCircleButton = () => {
      setFormFlag(false);
  }
  const { t } = useTranslation();

  if (signedIn) {
      console.log('Circcircles', circles);

      // Rare but possible: A shopper might want to create a new circle at that
      // window in time where they have configured a new email address but not
      // yet clicked the link in the verification email we sent. Watch for that
      // condition and no-op the 'Add Circle' button until email is verified.
      // Technically, we could allow using the soon-to-be-obsolete email at
      // Stripe as part of setting up the circle, and then just update the email
      // address at Stripe when shopper finally verifies. But it's not good for
      // a prospective email change to be hanging in limbo. It seems reasonable
      // to require getting email address in order before making a new circle.
      // Define a check here where 'true' means no email update is in progress:
      const noPendingEmailChange =
        shopper.unconfirmed_email == undefined  ||
        shopper.unconfirmed_email.length == 0

      const addCircleDisabledMessage =
        noPendingEmailChange ? '' :
          t('circle.addCircle.tippyContentA') +
          shopper.unconfirmed_email +
          t('circle.addCircle.tippyContentB')
  // To enable 'Add Circle', please verify '(email)' as your new email address.

      return (
          <article>
              <HeadingBuilder
                Size = "h2"
                headingText = {t('circle.formPart.headlineLabel')}
                // Circles for: shopper.identifier VID
                value = {shopper.identifier}
                videoUrl = "video/circles-screen.mp4"
              />
              { 
               circles.length == 0
                ? <HeadingBuilder
                    Size = "p"
                    headingText = {t('circle.formPart.noCircles')}
                    // You are not yet a member of any Shopping Circle
                  />
                : <CircleList circles = {circles} />
              }
              <br/>

              {formFlag
                ? <CircleForm lightUpAddCircleButton={lightUpAddCircleButton} />
                : noPendingEmailChange
                  ? <HeadingBuilder
                      buttonAction = {() => setFormFlag(true)}
                      headingText = {t('circle.addCircle.buttonLabel')}
                      // Add Circle

                      tippyAlertText = {t('circle.addCircle.tippyContent1')}
                      tippyText = {t('circle.addCircle.tippyContent1')}
                      tippyInfoText = {t('circle.addCircle.tippyContent2')}
                    />
                  : <HeadingBuilder
                      buttonAction   = { function() {} }
                      headingText    = {t('circle.addCircle.buttonLabel')}
                      // Add Circle

                      tippyText      = {Constants.DOT}
                      tippyAlertText = {addCircleDisabledMessage}
                      // To enable 'Add Circle', please verify ... email address
                      tippyInfoText  = {t('circle.addCircle.tippyContentC')}
                      // Have you clicked the verification link in the email...
                    />
              }
          </article>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default Circles;
