import React from 'react';
import { SkuEditLink } from '../components/SkuEditLink';
import { useTranslation } from "react-i18next";
import * as Constants from '../constants';
import TextToSpeech from '../components/TextToSpeech';

export function useLookups() {
  const { t } = useTranslation();

  function lookup_category ({
    category,
  }) {
    switch (category) {
      case  0: return t('sku.category.optionValue00');  // "Miscellaneous",
      case  1: return t('sku.category.optionValue01');  // "Fruits",
      case  2: return t('sku.category.optionValue02');  // "Vegetables",
      case  3: return t('sku.category.optionValue03');  // "Bread",
      case  4: return t('sku.category.optionValue04');  // "Baking",
      case  5: return t('sku.category.optionValue05');  // "Bakery",
      case  6: return t('sku.category.optionValue06');  // "Dessert",
      case  7: return t('sku.category.optionValue07');  // "Dairy",
      case  8: return t('sku.category.optionValue08');  // "Meat",
      case  9: return t('sku.category.optionValue09');  // "Seafood",
      case 10: return t('sku.category.optionValue10');  // "Alternative Protein",
      case 11: return t('sku.category.optionValue11');  // "Nuts",
      case 12: return t('sku.category.optionValue12');  // "Deli",
      case 13: return t('sku.category.optionValue13');  // "Soups",
      case 14: return t('sku.category.optionValue14');  // "Cans, Jars",
      case 15: return t('sku.category.optionValue15');  // "Pasta, Rice, Cereal",
      case 16: return t('sku.category.optionValue16');  // "Sauces",
      case 17: return t('sku.category.optionValue17');  // "Condiments",
      case 18: return t('sku.category.optionValue18');  // "Herbs, Spices",
      case 19: return t('sku.category.optionValue19');  // "Refrigerated Items",
      case 20: return t('sku.category.optionValue20');  // "Frozen Foods",
      case 21: return t('sku.category.optionValue21');  // "Snacks",
      case 22: return t('sku.category.optionValue22');  // "Beverages",
      case 23: return t('sku.category.optionValue23');  // "Paper Goods",
      case 24: return t('sku.category.optionValue24');  // "Household, Cleaning",
      case 25: return t('sku.category.optionValue25');  // "Personal Care, Beauty",
      case 26: return t('sku.category.optionValue26');  // "Health, Vitamins",
      case 27: return t('sku.category.optionValue27');  // "Pharmacy",
      case 28: return t('sku.category.optionValue28');  // "Baby Products",
      case 29: return t('sku.category.optionValue29');  // "Pet Care",
      case 30: return t('sku.category.optionValue30');  // "Office Supplies",
      case 31: return t('sku.category.optionValue31');  // "Clothing",
      case 32: return t('sku.category.optionValue32');  // "Automotive",
      case 33: return t('sku.category.optionValue33');  // "Electronics",
      case 34: return t('sku.category.optionValue34');  // "Patio, Lawn, Garden",
      case 35: return t('sku.category.optionValue35');  // "Kitchen", follows 23
      default: return t('sku.category.optionValue00');  // 'Miscellaneous'
    }
  };

  function lookup_category_eg ({
    category,
  }) {
    switch (parseInt(category)) {
      case  0: return t('sku.category.examples00');  // "Miscellaneous",
      case  1: return t('sku.category.examples01');  // "Fruits",
      case  2: return t('sku.category.examples02');  // "Vegetables",
      case  3: return t('sku.category.examples03');  // "Bread",
      case  4: return t('sku.category.examples04');  // "Baking",
      case  5: return t('sku.category.examples05');  // "Bakery",
      case  6: return t('sku.category.examples06');  // "Dessert",
      case  7: return t('sku.category.examples07');  // "Dairy",
      case  8: return t('sku.category.examples08');  // "Meat",
      case  9: return t('sku.category.examples09');  // "Seafood",
      case 10: return t('sku.category.examples10');  // "Alternative Protein",
      case 11: return t('sku.category.examples11');  // "Nuts",
      case 12: return t('sku.category.examples12');  // "Deli",
      case 13: return t('sku.category.examples13');  // "Soups",
      case 14: return t('sku.category.examples14');  // "Cans, Jars",
      case 15: return t('sku.category.examples15');  // "Pasta, Rice, Cereal",
      case 16: return t('sku.category.examples16');  // "Sauces",
      case 17: return t('sku.category.examples17');  // "Condiments",
      case 18: return t('sku.category.examples18');  // "Herbs, Spices",
      case 19: return t('sku.category.examples19');  // "Refrigerated Items",
      case 20: return t('sku.category.examples20');  // "Frozen Foods",
      case 21: return t('sku.category.examples21');  // "Snacks",
      case 22: return t('sku.category.examples22');  // "Beverages",
      case 23: return t('sku.category.examples23');  // "Paper Goods",
      case 24: return t('sku.category.examples24');  // "Household, Cleaning",
      case 25: return t('sku.category.examples25');  // "Personal Care, Beauty",
      case 26: return t('sku.category.examples26');  // "Health, Vitamins",
      case 27: return t('sku.category.examples27');  // "Pharmacy",
      case 28: return t('sku.category.examples28');  // "Baby Products",
      case 29: return t('sku.category.examples29');  // "Pet Care",
      case 30: return t('sku.category.examples30');  // "Office Supplies",
      case 31: return t('sku.category.examples31');  // "Clothing",
      case 32: return t('sku.category.examples32');  // "Automotive",
      case 33: return t('sku.category.examples33');  // "Electronics",
      case 34: return t('sku.category.examples34');  // "Patio, Lawn, Garden",
      case 35: return t('sku.category.examples35');  // "Kitchen", follows 23
      default: return t('sku.category.examples00');  // 'Miscellaneous'
    }
  };

  function lookup_tz_info ({
    tz_term,
  }) {
    switch (tz_term) {
      case  0: return t('sku.category.optionValue0');  // 'Other'
      case "guam":            return "Guam";
      case "midway_island":   return "Midway Island";
      case "american_samoa":  return "American Samoa";
      case "hawaii":          return "Hawaii";
      case "alaska":          return "Alaska";
      case "pacific_time_us_n_canada":  return "Pacific Time (US & Canada)";
      case "arizona":         return "Arizona";
      case "mountain_time_us_n_canada": return "Mountain Time (US & Canada)";
      case "central_time_us_n_canada":  return "Central Time (US & Canada)";
      case "indiana_east":    return "Indiana (East)";
      case "eastern_time_us_n_canada":  return "Eastern Time (US & Canada)";
      case "puerto_rico":     return "Puerto Rico";
      case "fiji":            return "Fiji";
      case "wellington":      return "Wellington";
      case "new_caledonia":   return "New Caledonia";
      case "sydney":          return "Sydney";
      case "melbourne":       return "Melbourne";
      case "darwin":          return "Darwin";
      case "tokyo":           return "Tokyo";
      case "seoul":           return "Seoul";
      case "perth":           return "Perth";
      case "singapore":       return "Singapore";
      case "bangkok":         return "Bangkok";
      case "rangoon":         return "Rangoon";
      case "dhaka":           return "Dhaka";
      case "kathmandu":       return "Kathmandu";
      case "kolcata":         return "Kolkata";
      case "mumbai":          return "Mumbai";
      case "islamabad":       return "Islamabad";
      case "karachi":         return "Karachi";
      case "kabul":           return "Kabul";
      case "baku":            return "Baku";
      case "tehran":          return "Tehran";
      case "nairobi":         return "Nairobi";
      case "cairo":           return "Cairo";
      case "jerusalem":       return "Jerusalem";
      case "kyiv":            return "Kyiv";
      case "riga":            return "Riga";
      case "berlin":          return "Berlin";
      case "madrid":          return "Madrid";
      case "west_central_africa": return "West Central Africa";
      case "london":          return "London";
      case "monrovia":        return "Monrovia";
      case "azores":          return "Azores";
      case "barasilia":       return "Brasilia";
      case "buenos_aires":    return "Buenos Aires";
      case "montevideo":      return "Montevideo";
      case "newfoundland":    return "Newfoundland";
      case "georgetown":      return "Georgetown";
      case "santiago":        return "Santiago";
      case "caracas":         return "Caracas";
      case "lima":            return "Lima";
      case "bogota":          return "Bogota";
      case "mexico_city":     return "Mexico City";
      case "saskatchewan":    return "Saskatchewan";
      case "mazatlan":        return "Mazatlan";
      case "tijuana":         return "Tijuana";
      default: return t('sku.category.optionValue0');  // 'Other'
    }
  };

  function lookup_status ({
    status,
  }) {
    console.log('what is status?', status, typeof status)
    switch (parseInt(status)) {
      // Return vals must be hard-coded, not locale-sensitive since the backend
      // responds to these specific terms. Using 't' as home base for constants.
      // Prior to calling this function, we can present a dropdown with
      // locale-sensitive terms to determine/provide numeric 'status' argument.
      case  0: return t('invitation.status.notResponded');  // 'NotResponded'
      case  1: return t('invitation.status.accepted');      // 'Accepted'
      case  2: return t('invitation.status.rejected');      // 'Rejected'
      case  3: return t('invitation.status.improper');      // 'Improper';
      default: return t('invitation.status.notResponded');  // 'NotResponded'
    }
  };

  function lookup_flavor ({
    flavor,
  }) {
    switch (parseInt(flavor)) {
      case  0: return t('invitation.flavor.optionValue0');  // 'circle_wants_shopper'
      case  1: return t('invitation.flavor.optionValue1');  // 'shopper_wants_circle'
      case  2: return t('invitation.flavor.optionValue2');  // 'leader_wants_new_leader'
      default: return t('invitation.flavor.optionValue0');  // 'circle_wants_shopper'
    }
  };

  function lookup_flavor_action ({
    flavor,
  }) {
    if (parseInt(flavor)) {
      switch (parseInt(flavor)) {
        case  0: return t('invitation.flavor.action0');  // 'to join Circle'
        // case  1: return t('invitation.flavor.action1');  // 'to join your Circle'
        case  2: return t('invitation.flavor.action2');  // 'to become Leader of Circle'
        default: return t('invitation.flavor.action0');  // 'to join Circle'
      }
    } else {
      switch (flavor) {
        case  'circle_wants_shopper':    return t('invitation.flavor.action0');
        case  'leader_wants_new_leader': return t('invitation.flavor.action2');
        default: return t('invitation.flavor.action0');  // 'to join Circle'
      }
    }
  };

  function lookup_flavor_headline_tippy ({
    flavor,
  }) {
    switch (parseInt(flavor)) {
      case  0: return t('invitation.formPart.headlineTippyContent0');  // 'invite...to Join Our Circle'
      // case  1: return t('invitation.formPart.headlineTippyContent1');  // 'invite...to Join Your Circle'
      case  2: return t('invitation.formPart.headlineTippyContent2');  // 'invite...to become its new Leader'
      default: return t('invitation.formPart.headlineTippyContent0');  // 'invite...to Join Our Circle'
    }
  };

  function lookup_flavor_invitation_tippy ({
    flavor,
  }) {
    switch (parseInt(flavor)) {
      case  0: return t('invitation.addShopperIdentifier.tippyContentJOCircle');  // 'no help with username'
      // case  1: return t('invitation.addShopperIdentifier.tippyContentJYCircle');  // ?
      case  2: return t('invitation.addShopperIdentifier.tippyContentBecomeLeader');  // 'find username via profile'
      default: return t('invitation.addShopperIdentifier.tippyContentJOCircle');  // 'no help with username'
    }
  };

  function lookup_flavor_label ({
    flavor,
  }) {
    console.log('what is flavorA?', flavor, typeof flavor)
    switch (flavor) {
      case  'circle_wants_shopper': return t('invitation.flavor.label0');  // 'Join Our Circle'
      case  'shopper_wants_circle': return t('invitation.flavor.label1');  // 'Join Your Circle'
      case  'leader_wants_new_leader': return t('invitation.flavor.label2');  // 'Become Leader'
      default: return t('invitation.flavor.label0');  // 'Join Our Circle'
    }
  };

  function lookup_flavor_response_notes ({
    flavor,
  }) {
    console.log('what is flavorB?', flavor, typeof flavor)

    const circleWantsShopperTippyContent =
        t('global.label.options')
      + t('invitation.status.tippyContentNotResponded')
      + ' // '
      + t('invitation.status.tippyContentAcceptRejectCircleWantsShopper')
      + ' // '
      + t('invitation.status.tippyContentImproper');

    const LeaderWantsNewLeaderTippyContent =
        t('global.label.options')
      + t('invitation.status.tippyContentNotResponded')
      + ' // '
      + t('invitation.status.tippyContentAcceptRejectLeaderWantsNewLeader')
      + ' // '
      + t('invitation.status.tippyContentImproper');

    switch (flavor) {
      // meanings for 'Not Responded', 'Accepted', 'Rejected', 'Improper'
      case  'circle_wants_shopper': return circleWantsShopperTippyContent;  // 'Join Our Circle'
      // case  'shopper_wants_circle': return shopperWantsCircleTippyContent;  // 'Join Your Circle'
      case  'leader_wants_new_leader': return LeaderWantsNewLeaderTippyContent;  // 'Become Leader'
      default: return t('invitation.status.tippyContentOptions0');  // 'Join Our Circle'
    }
  };


  function lookup_tax_type ({
    tax_type,
  }) {
    switch (parseInt(tax_type)) {
      case  0: return t('sku.tax_type.optionValue0');  // 'No Tax'
      case  1: return t('sku.tax_type.optionValue1');  // 'Regular'
      case  2: return t('sku.tax_type.optionValue2');  // 'Food'
      default: return t('sku.tax_type.optionValue0');  // 'No Tax'
    }
  };

  //function useLookups()
  return {
    lookup_tax_type,
    lookup_status,
    lookup_flavor,
    lookup_flavor_action,
    lookup_flavor_headline_tippy,
    lookup_flavor_invitation_tippy,
    lookup_flavor_label,
    lookup_flavor_response_notes,
    lookup_tz_info,
    lookup_category_eg,
    lookup_category
  }
}

export function useSkuSorts() {
  function s_by_sku_cat_brand_label( a, b )
  {
    //console.log("sbsli", a)
    if ( a.category < b.category){
      return -1;
    }
    if ( a.category > b.category){
      return 1;
    }
  
    if ( a.brand.toLowerCase() < b.brand.toLowerCase() ){
      return -1;
    }
    if ( a.brand.toLowerCase() > b.brand.toLowerCase() ){
      return 1;
    }
  
    if ( a.label.toLowerCase() < b.label.toLowerCase() ){
      return -1;
    }
    if ( a.label.toLowerCase() > b.label.toLowerCase() ){
      return 1;
    }
  
    if ( a.identifier < b.identifier){
      return -1;
    }
    if ( a.identifier > b.identifier){
      return 1;
    }
  
    return 0;
  }

  //function useSkuSorts()
  return {
    s_by_sku_cat_brand_label
  }
}

export function usePhrasing() {
  const { t } = useTranslation();

  function typicalWeightString ({
    sku,
  }) {
    // Either: 5.0 lb  -or-  ___ lb
    // Note maybe checking for typical_weight is unnecessary if Weighty items properly require typical_weight?
    // Rails checks WeightyItems for a typical_weight, but still do the check.

    // Hmm, should we also plan for missing 'sku.units'?

    if (sku.type !== 'WeightyItem') {
      return "[error - not weighty]"
    }

    return [
      sku.typical_weight ? sku.typical_weight : '___',
      " ",
      sku.units
    ]
  }

  const headingPlusEmValue = ({heading, value}) => {
    // elements in list need keys, so adapt with React.Fragment
    return [
      <React.Fragment key={heading}>
        {heading}
      </React.Fragment>,
      <em key={value}>&nbsp;{value}</em>
    ];
  }

  const headingPlusValue = ({heading, value}) => {
    // Elements in list need keys, so adapt with React.Fragment.
    // The 'hpv' tag below is make-believe, a meaningless tag
    return [
      <React.Fragment key={heading}>
        {heading}
      </React.Fragment>,
      <swa-hpv key={value}>&nbsp;{value}</swa-hpv>
    ];
  }

  const headingPlusPlayAudio = ({heading, origText}) => {
    // Elements in list need keys, so adapt with React.Fragment.
    return [
      <React.Fragment key={heading}>
        {heading}
      </React.Fragment>,
      <TextToSpeech key={origText.length} text={origText} />
    ];
  }

  const showMaxXChars = ({string="", max=10}) => {
    return string.length > max
      ? (string.slice(0, max) + "\u2026")
      : string;
  }

  const noDotZero = ({num}) => {
    if (Number(num)) {
    return `${Number.parseFloat(Number(num).toFixed(2))}`
    }
    return '';
  }
  const planParticulars = ({
    maxTrips, maxOrders, maxSkuSearches, period=t('global.monthly')
                          }) => {
    return (
      t('circle.planPriceBasic.ppHold')        + maxTrips +
      t('circle.planPriceBasic.ppTripsAnd')    + maxOrders +
      t('circle.planPriceBasic.ppOiMake')      + maxSkuSearches +
      t('circle.planPriceBasic.ppSkuSearches') + period
    )
  }
// Hold up to 140 Trips and 5000 OrderItems // Make up to 1000 Sku Searches moly

  const weightAndUnitsOfSku = ({sku, showWeightAsSkuEditLink, showUnitCount, showQtyUnits=true}) => {
    switch (sku.type) {
      case "DiscreteItem":
        return [
          showUnitCount
            ? ` (qty ${noDotZero({num: sku.unit_count})})`
            : ''
        ];
      case "WeightyItem":
        return [
          ', EST ',

          showWeightAsSkuEditLink 
            ? <SkuEditLink
                key = {sku.id}
                sku = {sku}
                linkTextPer = 'WeightyItem'
              />
            : typicalWeightString({sku})
        ];
      case "GranularItem":
        return [
          showQtyUnits
            ? `, ${noDotZero({num: sku.quantity})} ${sku.units}`
            : ''
        ];
      default:
        return [ 'Is item granular, discrete, or weighty?' ];
    }
  }


  function tippySkuIdentifierIsWeightyString ({
    isWeighty,
  }) {
    return isWeighty ? t('sku.identifier.weightyTippyContent') : ''
  }

  function typicalWeightLabel () {
    return t('sku.typical_weight.label') + ' ' + t('sku.typical_weight.label2');
  }

  function typicalWeightTippyText () {
    return t('sku.typical_weight.tippyContent') + ' '
         + t('sku.typical_weight.tippyContent2');
  }

  function priceLabel ({isWeighty}) {
    return isWeighty ? t('sku.price.weightyLabel') : t('sku.price.label');
  }

  function priceTippyText ({isWeighty}) {
    return isWeighty
     ? t('sku.price.weightyTippyContent')
     : t('sku.price.tippyContent') + t('sku.price.currencyCautionTippyContent');
  }


  function circleTaxTippyText () {
    return t('circle.tax_rate.tippyContent')
         + ' // '
         + t('circle.tax_rate.unitsExplainer65');
  }

  function circleFoodTaxTippyText () {
    return t('circle.tax_rate_food.tippyContent')
         + ' // '
         + t('circle.tax_rate_food.unitsExplainer25');
  }

  function tripTaxTippyText () {
    return t('trip.tax_rate.tippyContent')
         + ' '
         + t('trip.tax_rate.otherLocation')
         + ' // '
         + t('circle.tax_rate.unitsExplainer65');
  }

  function tripFoodTaxTippyText () {
    return t('trip.tax_rate_food.tippyContent')
         + ' '
         + t('trip.tax_rate.otherLocation')
         + ' // '
         + t('circle.tax_rate_food.unitsExplainer25');
  }

  function getShowTippyHelpIconLabel () {
    return t('shopper.showTippyHelpIcon.label1')
         + Constants.QMARK_SYM
         + t('shopper.showTippyHelpIcon.label2');
  }

  function getShowTooltipsTippy () {
    return t('shopper.showTippyHelpIcon.tippyContent10')
         + Constants.QMARK_SYM
         + t('shopper.showTippyHelpIcon.tippyContent20');
  }

  function getShowTooltipsTippyAudio () {
    return t('shopper.showTippyHelpIcon.tippyContent10')
         + t('shopper.showTippyHelpIcon.tippyContent20');
  }

  function getShowVideoTipsTippy () {
    return t('shopper.showVideoTipsIcon.tippyContent10')
         + Constants.VIDEO_SYM
         + t('shopper.showVideoTipsIcon.tippyContent20');
  }

  function getShowVideoTipsTippyAudio () {
    return t('shopper.showVideoTipsIcon.tippyContent10')
         + t('shopper.showVideoTipsIcon.tippyContent20');
  }

  function getShopperShortnameTippyText () {
    return t('shopper.shortname.tippyContent010')
         + t('shopper.shortname.tippyContent020')
         + t('shopper.shortname.tippyContent040');
  }

  function getShopperShortnameTippyAudio () {
    return t('shopper.shortname.tippyContent010')
         + t('shopper.shortname.tippyContent030')
         + t('shopper.shortname.tippyContent040');
  }

  function getUnitCountColHeaderTippyContent ({ isForTextNotAudio }) {
    return t('ordersList.unit_count_col.tippyContent')
         + t('global.paraMarker')
         + t('orderItem.blue_unit_count.tippyContentA')
         + (isForTextNotAudio ? Constants.O_COL_SYM : t('global.label.circle'))
         + t('orderItem.blue_unit_count.tippyContentB')
  }

  function getOColHeaderTippyContent ({ isForTextNotAudio }) {
    return t('ordersList.o_col.tippyContentA')
         + (isForTextNotAudio ? Constants.O_COL_SYM : t('global.label.circle'))
         + t('ordersList.o_col.tippyContentB')
  }

  function getOColTippyContent ({ isForTextNotAudio, isForSkusFound=false }) {
    const coreMsg =
      (isForTextNotAudio ? Constants.O_COL_SYM : t('global.label.circle'))
      + t('sku.o_col.tippyContentC')

    return isForSkusFound
      ? (t('sku.o_col.tippyContentA') + coreMsg)
      : (t('sku.o_col.tippyContentB') + coreMsg + t('sku.o_col.tippyContentD'))
  }

  function get_tax_type_suffix ({ taxType }) {
    switch (taxType) {
      case 'no_show':  // Return empty string
        return '';
      case 0:  // 'No Tax'
        return ' N';
      case 1:  // 'Regular'
        return ' R';
      case 2:  // 'Food'
        return ' F';
      default:
        return ' N';
    }
  }

  // Prepare Tippy text to show for postal_code of '99999'.
  // TODO: US-centric code. Only expects circle.country to be 'US', w/ Zip Codes
  function getTippyPostalCode ({ circle }) {
    return (
      circle.postal_code === '99999'
        ? t('circle.postal_code.tippyContent99999')
        : ''
      // '99999' is a valid placeholder value for a Zip Code. Updating the Circle to show an actual Zip Code value would be ideal.
    )
  } 

  function getSkuCatTippyInfo () {

    // needed:  const subscripts = [ '00', '01', '02', ... , '34', '35' ];
    const subscripts =
      Array.from({ length: Constants.LAST_SKU_CATEGORY_INDEX },
        (_, i) => i.toString().padStart(2, '0')
      );

    const initialValue = "";
    const skuCatTippyInfo = subscripts.reduce(
      (accumulator, subscript) => accumulator +
        t('sku.category.optionValue'+subscript) +
        ' - ' +
        t('sku.category.examples'+subscript) +
        ((parseInt(subscript) === subscripts.length - 1) ? "" : ' // '),
        // "Cans, Jars - tuna or canned meats, olives // "

      initialValue,
    );

    return skuCatTippyInfo;
  }

  // TODO: English text here like 'As Of' should become translation sensitive.
  function getAudioText ({ originalText = "" }) {
    const origTextArray =
      [ originalText ]
        .map(text => text.replace(/[ ]\/\//g, ' !'))
          // a bang (!) induces pause effect. "!.!" longer pause, but too long!
        .map(text => text.replace(/ft2/g, 'square feet'))
        .map(text => text.replace(/\boz\b/ig, 'ounces'))
        .map(text => text.replace(/\blb\b/g, 'pounds'))
        .map(text => text.replace(/\bAsOf\b/g, 'As Of'))
        .map(text => text.replace(/\ba\b/g, 'A'))
        .map(text => text.replace(/e[.]g[.][,]?/g, 'for example,'))
          // adding a ',' after 'for example' might get effect of audio pause
        .map(text => text.replace(/i[.]e[.]/g, 'that is,'))
        .map(text => text.replace(/[ ]?[(][?i][)]/g, ''))
          // rm instances of '(?)' and '(i)' - images for Alert and Info icon
        .map(text => text.replace(/\bLoom, Inc\b/g, 'Loom Inc'))
        .map(text => text.replace(/\b[Ss]ku/g, 'skew'))
        .map(text => text.replace(/\bshould lead to\b/g, 'should leed to'))
        .map(text => text.replace(/\bthat read CSV\b/g, 'that reed CSV'))
        .map(text => text.replace(/\bread on[.]/g, ' reed on.'))
        .map(text => text.replace(/\bPrice record\b/g, 'Price wreckird'))
        .map(text => text.replace(/\breconfigure\b/g, 're-configure'))
        .map(text => text.replace(/\bre[-]price\b/g, 'ree price'))

    return ( origTextArray[0] );
  }

  //function usePhrasing()
  return {
    headingPlusEmValue,
    headingPlusValue,
    headingPlusPlayAudio,
    getTippyPostalCode,
    circleTaxTippyText,
    circleFoodTaxTippyText,
    tripTaxTippyText,
    tripFoodTaxTippyText,
    getShowTippyHelpIconLabel,
    getShowTooltipsTippy,
    getShowTooltipsTippyAudio,
    getShowVideoTipsTippy,
    getShowVideoTipsTippyAudio,
    getShopperShortnameTippyText,
    getShopperShortnameTippyAudio,
    getUnitCountColHeaderTippyContent,
    getOColHeaderTippyContent,
    getOColTippyContent,
    getAudioText,
    getSkuCatTippyInfo,
    get_tax_type_suffix,
    tippySkuIdentifierIsWeightyString,
    typicalWeightLabel,
    typicalWeightTippyText,
    priceLabel,
    priceTippyText,
    showMaxXChars,
    noDotZero,
    planParticulars,
    weightAndUnitsOfSku,
    typicalWeightString
  }
}

export function useFormats() {
  function checkDecimalInput ({
    numInput,
  }) {
    return numInput.toString()
                   .replace(/[^.\d]/g, '')   // delete non-digits (and preserve '.')
                   .replace(/^0*[.]/, '0.')  // chg ".5|0.5|00.5" to "0.5"
                   .replace(/^0+(\d)/, "$1") // disallow "021" (rm leading zeros)
                   .replace(/^0+0$/, '0')     // chg "00|000" to "0"
                   ;
    // TODO: why does it sometimes take a click somewhere else to actuate?
  }

  function chgTippySepsToList ({ originalText = "" }) {
    return (
      <>
        {originalText.split(Constants.TIPPY_SEPARATOR).map((line, index) => (
          <React.Fragment key={index}>
            <li>{line}</li>
          </React.Fragment>
        ))}
      </>
    )
  }

  //function useFormats()
  return {
    chgTippySepsToList,
    checkDecimalInput
  }
}

export function usePricing() {

  function chooseDecimalScaleQty ({
    sku,
  }) {
    switch (sku.type) {
      case  "DiscreteItem": return 0;  // Unit Qty
      case  "GranularItem": return 1;  // Abs Amt

      case  "WeightyItem": return 2;   // Unit Qty
      // 2 for typical_weights like 12.87 lb, and we want to match cost exactly

      default: return 0;
    }
  };

  function s_by_price_date_desc( a, b )
  {
    if ( a.price_date < b.price_date ){
        return 1;
    }
    if ( a.price_date > b.price_date ){
      return -1;
    }
    return 0;
  }

  // TODO: The following is shameless green. Please clean up...
  function getUnitCost ({
    sku,
    sku_price,
  }) {

    if (sku_price < 0.00001) {
      return '0.00'
    }

    const unitCost =
      sku_price / (sku.quantity || sku.unit_count || 1);
      
    // return unitCost with three significant digits
    if (unitCost >= 1) {
      return unitCost.toFixed(2).toString();
    }

    if (unitCost >= 0.1) {
      return (Math.round(1000 * unitCost)  / 1000
        ).toFixed(3).toString();
    }

    if (unitCost >= 0.01) {
      return (Math.round(10000 * unitCost)  / 10000
        ).toFixed(4).toString();
    }

    if (unitCost >= 0.001) {
      return (Math.round(100000 * unitCost)  / 100000
        ).toFixed(5).toString();
    }

    if (unitCost >= 0.0001) {
      return (Math.round(1000000 * unitCost)  / 1000000
        ).toFixed(6).toString();
    }

    // Something is wrong if we get to this point!
    // Assumption: $0.0000[0-9][0-9][0-9] will look bad and trigger action by Shopper.
    // Shopper should change the parameters that make up unitCost.
    return (Math.round(10000000 * unitCost)  / 10000000
      ).toFixed(7).toString();
  }

  function getPrice ({
    sku_id,
    trip,
    prices
  }) {
    console.log("gp sku_id", sku_id)  // TODO: Should we test this?
    // Among all prices, find prices for this sku whose price_date occurs on or
    // before the trip date. Reverse sort this list by date and take first one.
    //const price_obj = prices.filter(p => p.sku.id == order.sku.id
    const price_obj = prices.filter(p => p.sku.id == sku_id
                                      && p.price_date <= trip.day)
                            .sort(s_by_price_date_desc)[0];
  
    console.log("gp price_obj", price_obj);
  
    const p =
        typeof price_obj !== 'undefined'
        ? ( typeof price_obj.price !== 'undefined'
            ? (
                ! Number.isNaN(price_obj.price)
                  ? price_obj.price
                  : 0
              )  // FIX ME: What's really the best in this case?
            : 0
          )
        : 0;
    
    if (p === 0) {
        // FIX ME: Poor messaging for this error:
        console.log("got price of '0.00' for sku:", sku_id);
    }

    // Get discount amount
    const d =
      typeof price_obj !== 'undefined'
        ? ( typeof price_obj.discount_amount !== 'undefined'
            ? (
                (! Number.isNaN(price_obj.discount_amount)  // discount_amount exists, (is not NaN)
                      && price_obj.discount_amount > 0
                      && trip.day >= (price_obj.discount_start_date || price_obj.price_date)
                      && trip.day <= price_obj.discount_end_date)  // "ISO Date" vals like: 2022-11-28
                  ? price_obj.discount_amount
                  : 0
              )
            : 0
          )
        : 0;
    console.log("oi gp discount: ", d);
    
    return p - d;
  }

  //function usePricing()
  return {
    chooseDecimalScaleQty,
    getUnitCost,
    getPrice
  }
}